import { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import {
  AssetAlternativeMetadataType,
  BaseAssetAlternativeType,
  ImageAssetAlternativeType,
} from '@remento/types/alternative';
import { AssetProcessingStatus, AssetType } from '@remento/types/asset';
import { UnknownError } from '@remento/types/error';
import { getStoryTitle } from '@remento/utils/entity/story';

import { StoryDialogContainer } from '@/modules/stories/containers/StoryDialog.container';
import { createStoriesManager, setActiveStoryId } from '@/modules/stories/states/stories.manager';
import {
  useAlternativeType,
  useAssetAlternativesQuery,
  useAssetImageUrl,
  useAssetQuery,
  usePollAssetId,
} from '@/services/api/asset';
import { useStoryIssues, useStoryQuery } from '@/services/api/story';

import {
  BookStoriesStore,
  toggleBookStoryIncluded,
  useBookStoryIndex,
  useIsBookStoryIncluded,
} from '../book-stories.state';
import { BookStoryList } from '../components/BookStoryList';

export interface BookStoryListItemContainerProps {
  store: BookStoriesStore;
  storyId: string;
  onChangeStories: () => void;
}

export function BookStoryListItemContainer({ store, storyId, onChangeStories }: BookStoryListItemContainerProps) {
  // Queries
  const storyQuery = useStoryQuery(storyId);
  const imageSrc = useAssetImageUrl(storyQuery.data?.imageAssetIds[0] ?? null, ImageAssetAlternativeType.SMALL);
  const recordingQuery = useAssetQuery(storyQuery.data?.recordingsIds[0]);
  const issues = useStoryIssues(storyId);
  const alternativesQuery = useAssetAlternativesQuery(storyQuery.data?.pdfAssetId);
  const originalAlternative = useAlternativeType(alternativesQuery.data, BaseAssetAlternativeType.ORIGINAL);
  usePollAssetId(storyQuery.data?.pdfAssetId, AssetProcessingStatus.PROCESSED);

  // State
  const index = useBookStoryIndex(store, storyId);
  const isIncluded = useIsBookStoryIncluded(store, storyId);
  const pageCount = useMemo(() => {
    if (originalAlternative?.metadata?.type == AssetAlternativeMetadataType.PDF_DOCUMENT) {
      return originalAlternative.metadata.pageCount;
    }
    return null;
  }, [originalAlternative?.metadata]);

  // Stories manager
  const personId = '';
  const storiesManager = useMemo(
    () => createStoriesManager([storyId], personId, null, null, 'story', true),
    [storyId, personId],
  );

  const handleToggle = useCallback(async () => {
    toggleBookStoryIncluded(store, storyId);
    onChangeStories();
  }, [onChangeStories, store, storyId]);

  const handleEdit = useCallback(() => {
    setActiveStoryId(storiesManager, storyId);
  }, [storiesManager, storyId]);

  // Create a portal to render the dialog.
  // This will make sure the dialog fills the entire screen.
  const portalRoot = document.getElementById('dialog');
  if (!portalRoot) {
    throw new UnknownError('Missing portal root for dialog');
  }

  const StoryDialogPortal = createPortal(
    <StoryDialogContainer storiesManager={storiesManager} showClose={true} />,
    portalRoot,
  );

  if (storyQuery.data == null) {
    return null;
  }

  return (
    <>
      <BookStoryList.Item
        id={storyQuery.data.id}
        title={getStoryTitle(storyQuery.data)}
        recordedAt={storyQuery.data.lastRecordedOn ?? 0}
        type={recordingQuery.data?.type === AssetType.AUDIO_RECORDING ? 'audio' : 'video'}
        index={index + 1}
        imageSrc={imageSrc}
        pageCount={pageCount}
        showPageCount={storyQuery.data.pdfAssetId != null}
        isIncluded={isIncluded}
        issues={issues?.map((i) => i.type) ?? null}
        onEdit={handleEdit}
        onToggle={handleToggle}
      />
      {StoryDialogPortal}
    </>
  );
}
