import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faList } from '@fortawesome/pro-regular-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PdfDocumentAssetAlternativeType, PdfDocumentMetadata } from '@remento/types/alternative';
import dayjs from 'dayjs';

import BookLoadingAnimationData from '@/assets/book-loading.lottie.json';
import { LottieAnimation } from '@/components/LottieAnimation';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMPage } from '@/components/RMPage';
import { RMPicker } from '@/components/RMPicker/RMPicker';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useBookOrderContext } from '@/modules/book-builder/book-order.context';
import {
  BookOrderHeaderContainer,
  BookOrderHeaderStep,
} from '@/modules/book-builder/containers/BookOrderHeader.container';
import { BookOrderHistoryDialogContainer } from '@/modules/book-builder/containers/BookOrderHistoryDialog.container';
import { BookQrCodeFormDialogContainer } from '@/modules/book-builder/containers/BookQrCodeFormDialog.container';
import { getBookOrderQuantityPath, getBookOrderStoriesPath } from '@/modules/routing';
import { useAlternativeFileUrl, useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useBookPreviewAssetIdQuery, useBookQuery, useProjectBookOrdersQuery } from '@/services/api/book';

import {
  AnimationContainer,
  BookContainer,
  BookLoadingContainer,
  BookViewer,
  Content,
  DownloadButton,
  LeftPanel,
  Link,
  ListDot,
  ListItem,
  MessageContainer,
  OrderHistoryButton,
  PickerWrapper,
  RightPanel,
} from './BookOrderPreviewPage.styles';

export function BookOrderPreviewPage() {
  // Route params
  const params = useParams();
  const projectId = params.projectId ?? '';
  const bookId = params.bookId ?? '';

  // Services
  const navigate = useNavigate();

  // Queries
  const bookOrdersQuery = useProjectBookOrdersQuery(projectId);
  const bookQuery = useBookQuery(bookId);
  const isStoryCountInsufficient = bookQuery.data?.storyIds == null || bookQuery.data.storyIds.length < 10;

  const bookPreviewAssetIdQuery = useBookPreviewAssetIdQuery(bookId);
  const boonPreviewAlternativesQuery = useAssetAlternativesQuery(bookPreviewAssetIdQuery.data ?? null);
  const bookPreviewAlternative = useAlternativeType(
    boonPreviewAlternativesQuery.data,
    PdfDocumentAssetAlternativeType.PREVIEW,
  );
  const bookPreviewUrl = useAlternativeFileUrl(bookPreviewAlternative?.id);
  const bookPageCount = bookPreviewAlternative ? (bookPreviewAlternative.metadata as PdfDocumentMetadata).pageCount : 0;
  const bookPageCountInsufficient = bookPageCount < 20;
  const bookPageCountLimitWarning = bookPageCount > 200;
  const bookPageCountLimitExceeded = bookPageCount > 380;

  // State
  const { quantityForm } = useBookOrderContext();

  // Confirmation State
  const [showConfirmationOpen, setShowConfirmationOpen] = useState(false);
  const [confirmationAccepted, setConfirmationAccepted] = useState(false);
  const [showConfirmationError, setShowConfirmationError] = useState(false);

  // Learn more state
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [qrCodePreferencesOpen, setQrCodePreferencesOpen] = useState(false);

  // Order history state
  const [orderHistoryDialogOpen, setOrderHistoryDialogOpen] = useState(false);

  const handlePrevious = useCallback(() => {
    navigate(getBookOrderStoriesPath(projectId, bookId));
  }, [bookId, navigate, projectId]);

  const nextButtonTooltip = useMemo(() => {
    if (bookPreviewUrl == null) {
      return 'Please wait while your book is being generated';
    }
    if (isStoryCountInsufficient) {
      return 'A minimum 10 stories are needed to order a book';
    }
    if (bookPageCountInsufficient) {
      return 'Your book must have at least 20 pages to proceed';
    }
    if (bookPageCountLimitExceeded) {
      return 'Decrease the number of pages in your book to proceed';
    }
    return null;
  }, [bookPageCountInsufficient, bookPageCountLimitExceeded, bookPreviewUrl, isStoryCountInsufficient]);

  const handleNext = useCallback(async () => {
    setShowConfirmationOpen(true);
  }, []);

  const handleAcceptConfirmation = useCallback(() => {
    if (confirmationAccepted === false) {
      setShowConfirmationError(true);
      return;
    }
    navigate(getBookOrderQuantityPath(projectId, bookId));
  }, [bookId, confirmationAccepted, navigate, projectId]);

  const handleCloseConfirmation = useCallback(() => {
    setShowConfirmationOpen(false);
    setConfirmationAccepted(false);
    setShowConfirmationError(false);
  }, []);

  return (
    <RMPage.Root>
      <BookOrderHeaderContainer
        projectId={projectId}
        bookId={bookId}
        step={BookOrderHeaderStep.Preview}
        quantityForm={quantityForm}
      />
      <RMPage.Content overflow="hidden">
        <Content>
          <LeftPanel>
            <RMText type="serif" size="l" color="on-surface-primary">
              Preview your book
            </RMText>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s" color="on-surface-primary">
              Please review the contents of your book to confirm the stories and order.
              {bookPreviewAlternative &&
                ` Your book has a total of ${bookPageCount} pages and was last updated ${dayjs(
                  bookPreviewAlternative.audit.create.date,
                ).fromNow()}.`}
              <br />
              <br />
              Learn more about how Remento QR codes work <Link onClick={() => setLearnMoreOpen(true)}>here</Link>.
            </RMText>

            {bookPageCountLimitWarning === true && bookPageCountLimitExceeded === false && (
              <>
                <RMSpacer spacing="2xl" direction="column" />
                <MessageContainer data-variant="warning">
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Heads up! Your book currently contains <b>{bookPageCount}</b> pages, which exceeds the page limit of
                    your current book credit (<b>200</b>). You can still order a book up to <b>380</b> pages at an added
                    cost of <b>$30</b> per book
                    <br />
                    <br />
                    To reduce the number of pages in your book, you can:
                  </RMText>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Edit the length of your stories
                    </RMText>
                  </ListItem>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Remove photos
                    </RMText>
                  </ListItem>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Decide not to include a story in your printed book
                    </RMText>
                  </ListItem>
                </MessageContainer>
              </>
            )}
            {bookPageCountLimitExceeded === true && (
              <>
                <RMSpacer spacing="2xl" direction="column" />
                <MessageContainer data-variant="error">
                  <RMText type="sans" size="s" color="on-surface-primary">
                    Heads up! Your book currently contains <b>{bookPageCount}</b> pages, which exceeds the maximum
                    number of pages you may print per book (<b>380</b>). To print your book, please reduce the number of
                    pages. We recommend taking any of the following steps:
                  </RMText>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Edit the length of your stories
                    </RMText>
                  </ListItem>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Remove photos
                    </RMText>
                  </ListItem>
                  <ListItem>
                    <ListDot />
                    <RMText type="sans" size="s" color="on-surface-primary">
                      Decide not to include a story in your printed book
                    </RMText>
                  </ListItem>
                </MessageContainer>
              </>
            )}

            <RMSpacer spacing="2xl" direction="column" />

            <RMStack direction="row" spacing="lg">
              <RMButton background="neutral" autoLoading onClick={handlePrevious}>
                Previous
              </RMButton>
              <RMTooltip message={nextButtonTooltip} side="top">
                <div>
                  <RMButton
                    style={{ minWidth: '6.25rem' }}
                    background="primary"
                    autoLoading
                    disabled={
                      isStoryCountInsufficient ||
                      bookPageCountInsufficient ||
                      bookPageCountLimitExceeded ||
                      bookPreviewUrl == null
                    }
                    onClick={handleNext}
                  >
                    Next
                  </RMButton>
                </div>
              </RMTooltip>
            </RMStack>
          </LeftPanel>
          <RightPanel>
            {(bookOrdersQuery.data?.length ?? 0) > 0 && (
              <OrderHistoryButton onClick={() => setOrderHistoryDialogOpen(true)}>
                <FontAwesomeIcon icon={faList} />
                View past orders
              </OrderHistoryButton>
            )}

            {bookPreviewUrl ? (
              <BookContainer>
                <BookViewer data={bookPreviewUrl} type="application/pdf" width="100%" height="100%" />
                <DownloadButton target="_blank" href={bookPreviewUrl}>
                  <FontAwesomeIcon icon={faDownload} color="var(--primary)" />
                  <RMText type="sans" size="xs" color="primary" bold>
                    Download preview
                  </RMText>
                </DownloadButton>
              </BookContainer>
            ) : (
              <BookLoadingContainer>
                <AnimationContainer>
                  <LottieAnimation animationData={BookLoadingAnimationData} loop />
                </AnimationContainer>
                <RMText type="serif" size="m" color="on-surface-primary">
                  Loading your book...
                </RMText>
                <RMText type="sans" size="xs" color="on-surface-primary">
                  This could take up to 5 minutes.
                </RMText>
              </BookLoadingContainer>
            )}
          </RightPanel>
        </Content>

        {/* Learn More modal */}
        <RMDialog.Root open={learnMoreOpen} onClose={() => setLearnMoreOpen(false)}>
          <RMDialog.Content>
            <RMDialog.Header
              title="About Remento’s QR codes"
              rightAdornment={<RMCloseButton onClick={() => setLearnMoreOpen(false)} />}
            />
            <RMDialog.Body>
              <RMText type="sans" size="s" color="on-surface-primary">
                When scanned, QR codes printed in Remento Books bring readers directly to the recording used to write
                each chapter. That way no future reader will ever be without the written story - or the voice behind.
                Learn more about how Remento uses QR codes via our{' '}
                <Link
                  href="https://help.remento.co/en/articles/8915122-how-does-the-remento-book-work#h_1b2421f60f"
                  target="_blank"
                >
                  FAQ
                </Link>
                , or adjust your QR code preferences{' '}
                <Link
                  onClick={() => {
                    setLearnMoreOpen(false);
                    setQrCodePreferencesOpen(true);
                  }}
                >
                  here
                </Link>
                .
              </RMText>
            </RMDialog.Body>
            <RMDialog.Footer>
              <RMButton background="primary" onClick={() => setLearnMoreOpen(false)}>
                Close
              </RMButton>
            </RMDialog.Footer>
          </RMDialog.Content>
        </RMDialog.Root>

        {/* QR Code preferences modal */}
        {qrCodePreferencesOpen && (
          <BookQrCodeFormDialogContainer bookId={bookId} onClose={() => setQrCodePreferencesOpen(false)} />
        )}

        {/* Confirmation modal */}
        <RMDialog.Root open={showConfirmationOpen}>
          <RMDialog.Content>
            <RMDialog.Header title="Are you sure everything is ready to print?" />

            <RMDialog.Body>
              <RMText type="sans" size="s" color="on-surface-primary">
                As a reminder, no one on our team reviews your stories. What you see in the preview is what your book
                will look like when printed.
              </RMText>

              <RMSpacer spacing="md" direction="column" />

              <RMText type="sans" size="s" color="on-surface-primary">
                Here are some examples of issues that our automated system might not catch.
              </RMText>

              <RMSpacer spacing="md" direction="column" />

              <ListItem>
                <ListDot />
                <RMText type="sans" size="s" color="on-surface-primary">
                  Typos, misspellings, and grammar mistakes
                </RMText>
              </ListItem>
              <ListItem>
                <ListDot />
                <RMText type="sans" size="s" color="on-surface-primary">
                  Missing, duplicate, or redundant stories
                </RMText>
              </ListItem>
              <ListItem>
                <ListDot />
                <RMText type="sans" size="s" color="on-surface-primary">
                  AI-generated titles that you may or may not like
                </RMText>
              </ListItem>

              <RMSpacer spacing="2xl" direction="column" />

              <PickerWrapper>
                <RMPicker.Root>
                  <RMPicker.Item
                    label="I confirm this book is ready to be printed as is."
                    value={'true'}
                    selected={confirmationAccepted}
                    error={showConfirmationError ? 'You must confirm in order to continue' : null}
                    onClick={() =>
                      setConfirmationAccepted((accepted) => {
                        setShowConfirmationError(false);
                        return !accepted;
                      })
                    }
                  />
                </RMPicker.Root>
              </PickerWrapper>
            </RMDialog.Body>

            <RMDialog.Footer>
              <RMButton background="neutral" onClick={handleCloseConfirmation}>
                Cancel
              </RMButton>
              <RMButton background="primary" onClick={handleAcceptConfirmation}>
                Continue
              </RMButton>
            </RMDialog.Footer>
          </RMDialog.Content>
        </RMDialog.Root>

        {orderHistoryDialogOpen && (
          <BookOrderHistoryDialogContainer projectId={projectId} onClose={() => setOrderHistoryDialogOpen(false)} />
        )}
      </RMPage.Content>
    </RMPage.Root>
  );
}
