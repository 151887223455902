import { styled } from '@linaria/react';

import { RMLink } from '@/components/RMLink/RMLink';

export const LeftContent = styled.div`
  // header + padding + border
  top: calc(100px + var(--spacing-2xl) + 1px);
  position: sticky;

  body[data-tablet='true'] & {
    position: static;
    top: unset;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  body[data-tablet='true'] & {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2xl);
  }
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;

  body[data-tablet='true'] & {
    justify-content: flex-start;
  }
`;

export const LeftPanel = styled.div`
  position: absolute;
  width: 45%;

  body[data-tablet='true'] & {
    width: 22.5rem;
    position: static;
  }
`;

export const RightPanel = styled.div`
  width: 55%;
  overflow: auto;

  body[data-tablet='true'] & {
    width: 100%;
  }
`;

export const IssuesMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-xs);
  padding: var(--spacing-md);
  border-radius: var(--radius-round);
  background-color: var(--surface-bright);
  border: 2px solid var(--caution);
  border-style: solid;
`;

export const FaqLink = styled.a`
  color: var(--caution);
  text-decoration: underline;
`;

export const ContinueDialogBody = styled.div`
  min-height: 5rem;
`;

export const OrderHistoryButton = styled(RMLink)`
  position: absolute;
  top: calc(var(--spacing-xl) * -1);
  right: 0;

  display: flex;
  gap: var(--spacing-xs);
  font-weight: 700;
  align-items: center;
`;
